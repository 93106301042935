import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex h-100" }
const _hoisted_2 = { class: "d-flex flex-center flex-column flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArcAside = _resolveComponent("ArcAside")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.asideEnabled)
      ? (_openBlock(), _createBlock(_component_ArcAside, {
          key: 0,
          lightLogo: _ctx.themeLightLogo,
          darkLogo: _ctx.themeDarkLogo
        }, null, 8, ["lightLogo", "darkLogo"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: "d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed",
      style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.getIllustrationsPath('14.png') + ')' })
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view)
      ])
    ], 4)
  ]))
}