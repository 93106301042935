
import { defineComponent, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { Actions } from "@/core/store/enums/StoreEnums";
import { getIllustrationsPath } from "@/core/helpers/assets";
import ArcAside from "@/core/layout/aside/Aside.vue";
import {
  asideEnabled,
  themeDarkLogo,
  themeLightLogo
} from "@/core/helpers/config";

export default defineComponent({
  name: "auth",
  components: {ArcAside},
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch(Actions.ADD_BODY_CLASSNAME, "bg-body");
    });

    onUnmounted(() => {
      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "bg-body");
    });

    return {
      getIllustrationsPath,
      asideEnabled,
      themeDarkLogo,
      themeLightLogo
    };
  },
});
